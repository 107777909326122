(function($) {
  /*
   * Add Smooth Scrolling Transition */
  $('.header-click').on('click', function(event) {
    event.preventDefault();
    var target = $('.storefront-product-section').offset().top - 60;

    $('html, body').animate({ scrollTop: target }, 500);
  });

   /*
   * Video modal */
  $('.js-video').on('click', function(event) {
    event.preventDefault();
    var $modal = $(this).next('.video-modal');
    var $iframe = $(this).next('.video-modal').find('iframe');
    var $src = $iframe.attr('src');

    $modal.addClass('show');

    $modal.find('.close').on('click', function() {
      if ($modal.hasClass('show')) {
      	$iframe.attr('src',$src);
      	$modal.removeClass('show');
      }
    });

  });

})(jQuery);