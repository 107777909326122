(function($) {
  // Move after featured widget inside featured products section
  $("#bulk-discount").appendTo(".storefront-featured-products");

  // Search 
  var $search_btn = $('.js-search a');
  var $search_form = $('.searchform');
  var $search_input = $('.searchform input[type="text"]');
  var $search_dismiss = $('.searchform .close');
  var VISIBLE = 'is-visible';
  $search_btn.on('click', function(e) {
    e.preventDefault();

    $search_form.toggleClass(VISIBLE);
    $search_input.focus();
  });

  $search_dismiss.on('click', function(e) {
    e.preventDefault();

    $search_form.removeClass(VISIBLE);
  });

  // Single Product
  $(".woocommerce-button-group").appendTo(".woocommerce-product-gallery");

  // Target Details
  function openTarget() {
    var hash = location.hash.substring(1);
    if (hash) var details = document.getElementById(hash);
    if (details && details.tagName.toLowerCase() === 'details') details.open = true;
  }
  window.addEventListener('hashchange', openTarget);
  openTarget();


  var base64Img;
  var imgPath = theme_directory + "/dist/images/site-logo.png";
  console.log(imgPath);
    imgToBase64(imgPath, function(base64) {
      base64Img = base64;
  });

     function imgToBase64(src, callback) {
        var outputFormat = src.substr(-3) === 'png' ? 'image/png' : 'image/jpeg';
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function() {
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var dataURL;
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
            img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            img.src = src;
        }
    }

  $('#cmd').on('click', function() {

    var doc = new jsPDF();
    var totalPagesExp = "{total_pages_count_string}";
    doc.autoTable({
      startY: 40,
      html: '#wc-user-details',
      theme: 'plain', 
      didDrawPage: function (data) {
            // Header
            doc.setFontSize(20);
            doc.setTextColor(40); 
            doc.setFontStyle('normal');
            if (base64Img) {
                doc.addImage(base64Img, 'PNG', data.settings.margin.left, 15, 60, 10); 
            } 

            // Footer
            var str = "4425-C Treat Blvd. Concord, CA 94521 — Tax ID #: 32-0311443";
            doc.setFontSize(10);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
        margin: {top: 30}
    });
    var finalY = doc.previousAutoTable.finalY;

    doc.text("Cart Details", 14, finalY + 15);
    doc.autoTable({
      startY: finalY + 20,
      html: '#wc-cart-pdf',
      theme: 'plain'
    }); 


    doc.save('cart.pdf');

  });

})(jQuery);